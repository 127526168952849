import { serverApiConfig } from '../config';
import { useAppDispatch } from '../redux/app';
import { setPositions } from '../redux/reducer/positions';
import { setOrders } from '../redux/reducer/orders';

const { PositionClient } = require('../grpc/generated/position_grpc_web_pb');

const PositionSteam = () => {
  const dispatch = useAppDispatch();
  let stream = null;
  const subscribePosition = async (request, metadata) => {
    try {
      let positionClient = new PositionClient(
        serverApiConfig(localStorage.getItem('trade.server')),
        null,
        null
      );
     stream = positionClient.getPosition(request, metadata);
     stream.on('data', function (response) {
        const data = {
          positions: response.u[0],
          orders: response.u[1]
        };
        dispatch(setPositions(data.positions))
        dispatch(setOrders(data.orders))
      });
     stream.on('error', function () {
        console.log('error');
       stream?.cancel();
        setTimeout(() => {
          console.log('Reconect Position');
        }, 5000);
      });
     stream.on('status', function (status) {
        // console.log(status.code);
        // console.log(status.details);
        // console.log(status.metadata);
      });
     stream.on('end', function () {
        // console.log('end');
      });
    } catch (e) {
      console.log(e);
    }
  };

  return { subscribePosition };
};

export default PositionSteam;
