import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerAccount } from '../../redux/reducer/account';
import useAuth from '../../hooks/useAuth';
import { AuthClient } from '../../grpc/generated/auth_grpc_web_pb';
import { TokenRequest } from '../../grpc/generated/auth_pb';
import { serverApiConfig } from '../../config';
import { Notification } from '../../utilities/alert';
import { origin } from '../Login';
import { getuser } from '../../firebase';

const typeServer = { api: 'Live1' };
const account = { Standard: 'Standard', StandardBonus: 'Standard+', Cent: 'Cent' };

const DrawerAccount = () => {
  const [idKey, setKey] = useState({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { accounts, activeAccount, getFirebaseCustom, user } = useAuth();

  const { isDrawer } = useSelector(({ account }) => account);

  useEffect(() => {
    if (accounts) {
      setKey(
        accounts.reduce((a, b) => {
          return {
            ...a,
            [b.id]: b
          };
        }, {})
      );
      setLoading(false);
    }
  }, [accounts]);

  const onSwitchAccount = async (item) => {
    const tokenRequest = new TokenRequest();
    tokenRequest.setMainToken(await getuser());
    tokenRequest.setAccountTradeId(item.id);

    let authClient = new AuthClient(serverApiConfig(item.api_server), null, null);

    await authClient.getToken(tokenRequest, {}, (err, response_) => {
      if (response_ !== null) {
        localStorage.setItem('iuxmarkets.active.account', item.id);
        // set trade server when response successful
        localStorage.setItem('trade.server', item.api_server);
        window.electron?.store.set('trade.server', item.api_server);

        localStorage.setItem('trade.token', response_.u[0]);

        // localStorage.removeItem('symbol_menu_list');
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        Notification({
          message: err.message,
          type: 'danger'
        });
      }
    });
  };

  const getOriginUrl = (countryId, originUrl) => {
    return origin[originUrl];
  };

  const encoded = btoa(window.localStorage.getItem('trade.access.token'));
  return (
    <div
      style={{
        position: 'fixed',
        height: '100vh',
        width: '300px',
        background: 'rgb(14 15 18)',
        right: 0,
        top: 0,
        transform: isDrawer ? 'translateX(0%)' : 'translateX(100%)',
        zIndex: 99,
        transition: 'all 0.3s',
        borderLeft: '1px solid #232323'
      }}>
      <div className="drawer-header">
        <div className="title">
          {idKey !== null ? (
            <>
              <span>{idKey[activeAccount]?.account_number} - IUXMarkets-</span>
              <span className="capitalize">
                {typeServer[idKey[activeAccount]?.api_server]
                  ? typeServer[idKey[activeAccount]?.api_server]
                  : idKey[activeAccount]?.api_server}
              </span>
            </>
          ) : null}
        </div>
        <div className="flex">
          <svg
            className="close"
            onClick={() => dispatch(setDrawerAccount(false))}
            viewBox="0 0 24 24"
            width="20"
            height="20"
            role="presentation"
            focusable="false"
            fill="#fff"
            aria-hidden="true">
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414Z"
              clipRule="evenodd"></path>
          </svg>
        </div>
      </div>
      <div className="flex flex-col justify-between h-[calc(100vh_-_50px)]">
        <div className="drawer-body">
          {accounts.map((e, index) => (
            <div
              key={index}
              className={`item ${activeAccount.toString() === e.id.toString() ? 'active' : ''}`}
              style={{ color: 'rgb(156 176 194)' }}
              onClick={() => onSwitchAccount(e)}>
              <div className="info">
                <div>
                  <img src="/USD.png" width="20" height="20" style={{ borderRadius: '50%' }} />
                </div>
                <div className="account_info">
                  <div className={`account_type ${index === 0 ? 'active' : ''} font-bold`}>
                    <span>{`${e.account_number}`}</span>
                  </div>
                  <div>
                    <span
                      className={`capitalize ${
                        e.type === 'demo' ? 'text-[#ff6c00]' : 'text-[#169376]'
                      }`}>
                      {typeServer[e.api_server] ? typeServer[e.api_server] : e.api_server}
                    </span>
                    <span>
                      {' - '}
                      <span className="capitalize text-sm">{`${
                        account[e.account_type] ?? e.account_type
                      }`}</span>
                    </span>{' '}
                  </div>
                  {/* <div className="current_balance">{`${e.current_balance} ${e.currency} `}</div> */}
                </div>
              </div>
              <div className="flex flex-col">
                {/* <span className="text-sm capitalize mb-1">
                {`${e.api_server !== 'api' ? `${e.api_server}` : `live1`}`}
              </span> */}
                <span className="flex">
                  {e.id === +activeAccount ? (
                    <svg
                      width="15"
                      height="15"
                      className="ohGHbMkZKR m-auto"
                      viewBox="0 0 24 24"
                      role="presentation"
                      focusable="false"
                      aria-hidden="true">
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 4a8 8 0 1 0 5.653 13.66 1 1 0 0 1 1.413 1.416A9.972 9.972 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c4.091 0 7.607 2.478 9.158 6l.65-1.511a1 1 0 1 1 1.837.79l-1.777 4.134a1 1 0 0 1-1.314.524l-4.134-1.778a1 1 0 0 1 .79-1.837l2.324.999C18.421 6.228 15.462 4 12 4Z"
                        clipRule="evenodd"></path>
                    </svg>
                  ) : (
                    ''
                    // <svg
                    //   width="20"
                    //   height="20"
                    //   className="ohGHbMkZKR"
                    //   viewBox="0 0 24 24"
                    //   role="presentation"
                    //   focusable="false"
                    //   aria-hidden="true">
                    //   <path
                    //     fill="currentColor"
                    //     d="M14 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 18a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"></path>
                    // </svg>
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="px-2 mb-2">
          <button
            className="w-full h-[40px] rounded-md text-[#169376] border border-[#169376]"
            onClick={() =>
              getFirebaseCustom().then((token) => {
                window.open(
                  `${
                    window?.electron
                      ? 'https://portal.iux.com'
                      : getOriginUrl(
                        user?.data?.personal_detail?.country_id,
                        window.location.origin
                      )
                  }/en/wallet/account/dashboard/${token}`
                );
              })
            }>
            Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default DrawerAccount;
