import React, { useMemo, useEffect, useState } from "react";
const RE_DIGIT = new RegExp(/^\d+$/);

export default function OtpInput({
  value = "",
  valueLength,
  onChange,
  isError,
}) {
  const [valueItemsAll, setvalueItemsAll] = useState();

  const valueItems = () => {
    const valueArray = value ? value.split("") : [];
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];
      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    setvalueItemsAll(items);
  };

  useEffect(() => {
    valueItems();
  }, [value, valueLength]);

  const focusToNextInput = (target) => {
    const nextElementSibling = target.nextElementSibling;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (target) => {
    const previousElementSibling = target.previousElementSibling;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };
  
  const inputOnKeyDown = (e) => {
    const { key } = e;
    const target = e.target;

    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;
    
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== "Backspace" || targetValue !== "") {
      return;
    }

    focusToPrevInput(target);
  };

  const inputOnFocus = (e) => {
    const { target } = e;

    target.setSelectionRange(0, target.value.length);
  };

  const inputOnChange = (e, idx) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " ";

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue, target);

      if (!isTargetValueDigit) {
        return;
      }

      // Move to the next input or unfocus if the last digit is entered
      if (idx === valueLength - 1) {
        target.blur(); // Unfocus the input when the last digit is entered
      } else {
        focusToNextInput(target);
      }
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);
      target.blur(); // Unfocus when maximum valueLength is entered
    }
  };

  return (
    <div className="flex justify-center gap-2">
      {valueItemsAll &&
        valueItemsAll.map((digit, idx) => (
          <input
            key={idx}
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{1}"
            maxLength={valueLength}
            className={`${isError ? `border border-[red]` : `border border-[#D9D9D9]`} w-[32px] h-10 rounded-lg text-center text-white bg-transparent focus-visible:border focus-visible:border-[#1cb894] focus-visible:outline-none focus-visible:caret-[#1cb894] outline-[#D9D9D9]`}
            value={digit}
            onChange={(e) => inputOnChange(e, idx)}
            onKeyDown={inputOnKeyDown}
            onFocus={inputOnFocus}
          />
        ))}
    </div>
  );
}
