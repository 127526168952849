import { createSelector } from '@reduxjs/toolkit';

const makeBidAskBySymbolSelector = () =>
  createSelector(
    (state) => state.bidask,
    (_, symbol) => symbol,
    (bidask, symbol) => {
      return bidask?.bidask[symbol] ?? null;
    }
  );

const makeBidAskSelector = () =>
  createSelector(
    (state) => state,
    (_, isBid) => isBid,
    (state, isBid) => {
      return state.bidask?.bidask[state.symbol.active]?.[isBid] ?? 0;
    }
  );

const makeTradeDialogSelector = () =>
  createSelector(
    (state) => state.tradeDialog,
    (_) => null,
    (tradeDialog) => {
      return tradeDialog;
    }
  );

const makeMenuSelector = () =>
  createSelector(
    (state) => state.symbol,
    (_) => null,
    ({ menu }) => ({ menu })
  );

const makePictureSelector = () =>
  createSelector(
    (state) => state.symbol.instruments,
    (_, id) => id,
    (instruments, id) => {
      return instruments.reduce(
        (a, b) => ({
          ...a,
          [b.symbol]: b.logo
        }),
        {}
      );
    }
  );

const makeOrderSelector = () =>
  createSelector(
    (state) => state.orders?.orders,
    (_) => null,
    (orders) => {
      let order = [];
      orders.forEach((element) => {
        order.push({
          positionId: element[1] ?? 0,
          order: element[2] ?? 0,
          symbol: element[3] ?? '',
          digits: element[4] ?? 0,
          time_create: element[8] ?? 0,
          time_expiration: element[10] ?? 0,
          digisCurrency: element[30] ?? 0,
          contractSize: element[5] ?? 0,
          type: element[13] ?? 0,
          priceOrder: element[16] ?? 0,
          priceTrigger: element[17] ?? 0, // The price, at which a Limit order is placed when the Stop Limit order triggers.
          priceCurrent: element[18], //  The current price of the symbol, for which an order has been placed.
          priceSL: element[19] ?? 0,
          priceTP: element[20] ?? 0,
          volumeInitial: element[21] ?? 0,
          volumeCurrent: element[23], // the current unfilled volume of an order.
          comment: element[25] ?? 'No Comment'
        });
      });
      return order;
    }
  );

const makePositionNoReduceSelector = () =>
  createSelector(
    (state) => state.positions?.positions,
    (_, symbol) => symbol,
    (positions) => {
      return positions;
    }
  );

const makePositionSelector = () =>
  createSelector(
    (state) => state.positions?.positions,
    (_, symbol) => symbol,
    (positions) => {
      let position = [];
      positions.forEach((element) => {
        position.push({
          position: element[0],
          symbol: element[1],
          action: element[2] ?? 0,
          digits: element[3],
          digitsCurrency: element[4],
          contractSize: element[5],
          timeCreate: element[6],
          timeUpdate: element[7],
          priceOpen: element[8],
          priceCurrent: element[9],
          priceSL: element[10] ?? 0,
          priceTP: element[11] ?? 0,
          volume: element[12],
          profit: element[13] ?? 0,
          reason: element[14]
        });
      });
      return position;
    }
  );

const makeStatSelector = () =>
  createSelector(
    (state) => state.stat,
    (_, symbolArr) => symbolArr,
    (stat, symbolArr) => {
      // console.log(stat)
      // console.log('open', open)
      // console.log(stat);
      // console.log(first)
      // const fil = stat?.filter((item) => symbol.includes(item.symbol));
      // const t = tick?.filter((item) => symbol.includes(item.symbol));
      // const ba = t.reduce((a, b) => ({ ...a, [b.symbol]: b }), {});
      // return fil.reduce(
      //   (a, b) => ({
      //     ...a,
      //     [b.symbol]: {
      //       ...b,
      //       bid: ba[b.symbol].bid,
      //       ask: ba[b.symbol].ask
      //     }
      //   }),
      //   {}
      // );
    }
  );

const makeInstrumentInfoSelector = () =>
  createSelector(
    (state) => state.symbol.instruments,
    (_, symbolArr) => symbolArr,
    (instruments, symbolArr) => {
      return instruments.filter((e) => e.symbol === symbolArr)[0] ?? null;
    }
  );

const makeInstrumentByIdSelector = () =>
  createSelector(
    (state) => state.symbol.instruments,
    (_, id) => id,
    (instruments, id) => {
      return instruments.find((e) => e.symbol === id);
    }
  );

export {
  makeBidAskBySymbolSelector,
  makeTradeDialogSelector,
  makeMenuSelector,
  makePictureSelector,
  makePositionSelector,
  makeStatSelector,
  makeInstrumentByIdSelector,
  makeBidAskSelector,
  makeOrderSelector,
  makePositionNoReduceSelector,
  makeInstrumentInfoSelector
};
