import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DialogComponent from '../../Dialog'
import DateFilter from '../DateFilter'
import { FILTER_LIST } from '../../../constant'
import { actionsTypes } from '../../../config'

const Orders = ({ dialog, setDialog }) => {
  const { orders } = useSelector(({ histories }) => histories)
  const [orderInfo, setOrderInfo] = useState({
    failed: 0,
    canceled: 0,
    expired: 0,
    total: 0,
  })
  const [marketOption, setMarketOption] = useState(7)
  const { instruments } = useSelector(({ symbol }) => symbol)
  const { isOpen, type } = useSelector(({ tradeDialog }) => tradeDialog)
  const [custom, setCustom] = useState(false)
  const [fromTo, setFromTo] = useState({
    from: '',
    to: '',
  })

  const [history, setHistory] = useState([])

  const [historyFilter, setOrderFilter] = useState({
    index: 7,
    data: [],
  })

  const [imageOfOrder, setImageOfOrder] = useState({ ['']: '' })

  useEffect(() => {
    if (orders.length > 0) {
      let history = []
      orders.forEach((item, index) => {
        let historyInfo = {
          order: item[0],
          external_id: item[1],
          login: item[2],
          dealer: item[3],
          symbol: item[4],
          digits: item[5],
          digits_currency: item[6],
          contract_size: item[7],
          state: item[8],
          reason: item[9],
          time_setup: item[10],
          time_expiration: item[11],
          time_done: item[12],
          type: item[13] ?? 0,
          type_fill: item[14],
          type_time: item[15],
          price_order: item[16],
          price_trigger: item[17],
          price_current: item[18],
          price_sl: item[19],
          price_tp: item[20],
          volume_initial: item[21],
          volume_current: item[22],
          expert_id: item[23],
          position_id: item[24],
          comment: item[25],
          time_setup_msc: item[30],
          time_done_msc: item[31],
          rate_margin: item[32],
          time_format: moment.unix(item[12]).format('YYYY-MM-DD'),
        }
        history.push(historyInfo)
      })

      const today = moment()
      const filtered = history.filter((e) => moment(e.time_format).isSame(today, 'day'))
      const failed = filtered.filter((e) => e.state === 4).length
      const canceled = filtered.filter((e) => e.state === 2).length
      const expired = filtered.filter((e) => e.state === 6).length
      const total = filtered.filter((e) => e.state !== undefined).length

      setOrderInfo({
        failed,
        canceled,
        expired,
        total,
      })
      setHistory(history)
      setOrderFilter((prev) => ({
        index: 7,
        data: filtered.reverse(),
      }))
    }
  }, [orders])

  const handleFilterHistory = (value) => {
    let today = moment()
    let lastWeek = moment().subtract(7, 'day').unix()
    let lastMonth = moment().subtract(1, 'month').unix()
    let last3Month = moment().subtract(3, 'month').unix()
    let last6Month = moment().subtract(6, 'month').unix()
    let lastYear = moment().subtract(1, 'year').unix()
    let time = moment()
    if (value === 0) time = lastWeek
    if (value === 1) time = lastMonth
    if (value === 2) time = last3Month
    if (value === 3) time = last6Month
    if (value === 4) time = lastYear
    if (value === 6) time = 0
    if (value === 7) time = today
    try {
      let filtered = []
      if (value === 7) filtered = history.filter((e) => moment(e.time_format).isSame(time, 'day'))
      else filtered = history.filter((e) => moment(e.time_done).isAfter(time))
      if (value !== 5) {
        const failed = filtered.filter((e) => e.state === 4).length
        const canceled = filtered.filter((e) => e.state === 2).length
        const expired = filtered.filter((e) => e.state === 6).length
        const total = filtered.filter((e) => e.state !== undefined).length
        setOrderInfo({
          failed,
          canceled,
          expired,
          total,
        })
        setOrderFilter((prev) => ({
          index: value,
          data: filtered.reverse(),
        }))
        setCustom(false)
      } else {
        setCustom(true)
        setOrderFilter((prev) => ({
          ...prev,
          index: value,
        }))
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleCustom = (value, from) => {
    if (from) {
      setFromTo((prev) => ({
        ...prev,
        from: moment(value).utc().format('YYYY-MM-DD'),
      }))
    } else {
      setFromTo((prev) => ({
        ...prev,
        to: moment(value).add(1, 'day').utc().format('YYYY-MM-DD'),
      }))
    }
  }

  const submitDate = () => {
    const filtered = history.filter((e) => moment(e.time_format).isBetween(fromTo.from, fromTo.to))
    setOrderFilter((prev) => ({
      ...prev,
      data: filtered,
    }))
  }

  useEffect(() => {
    const a = instruments.reduce((a, b) => {
      return {
        ...a,
        [b.symbol]: b.logo ?? [],
      }
    }, {})
    setImageOfOrder(a)
  }, [history])

  const renderAction = (x) => {
    switch (x) {
      default:
        return (
          <div style={{ color: actionsTypes[x]?.color }} className="font-[600]">
            {actionsTypes[x]?.name}
          </div>
        )
    }
  }

  return (
    <div>
      <div className="history-dialog">
        <div className="grid grid-cols-2 p-3 gap-y-2 text-[#c1c1c1]">
          <div className="">Filled</div>
          <div className="text-end">{orderInfo.failed ?? 0}</div>
          <div className="">Canceled</div>
          <div className="text-end">{orderInfo.canceled}</div>
          <div className="">Expired</div>
          <div className="text-end">{orderInfo.expired}</div>
          <div className="">Total</div>
          <div className="text-end">{orderInfo.total}</div>
        </div>
        <div id="custom-order-scroll" className="table-order-scroll-order history">
          {historyFilter.data.map((e, i) => (
            <div
              key={i}
              className="table-body hover:bg-[#141414] h-[84px]"
              style={{
                padding: '14px 8px',
                height: 70,
                opacity: type === 'history' ? 1 : 0,
              }}>
              <div className="flex h-full">
                <div className="flex w-full">
                  <div className="w-10">
                    <img
                      src={`https://storage.googleapis.com/iux-storage/symbol/${e.symbol}.png`}
                      alt="..."
                      onError={({ currentTarget }) => {
                        currentTarget.src = 'globe.svg'
                      }}
                      className="w-full rounded-[50%]"
                    />
                  </div>
                  <div className="ml-3 flex flex-col justify-between">
                    <div className="flex items-baseline">
                      <div className="text-white text-sm">{e.symbol}</div>
                      <span className="ml-1">{renderAction(e.type)}</span>
                    </div>
                    <div className="flex text-white">
                      <div style={{ color: '#999999' }} className="flex items-center">
                        <div className="text-sm">{e.volume_initial / 10000}</div>
                        {e.price_current && e.price_current ? (
                          <span className={`text-sm mx-1 text-[${actionsTypes[e.type]?.color}]`}>
                            at
                          </span>
                        ) : (
                          <div className="text-sm">{e.comment}</div>
                        )}
                        <div className={`text-sm text-[${actionsTypes[e.type]?.color}]`}>
                          {e.price_current?.toFixed(e.digits)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-between">
                  <div
                    style={{
                      color: e?.state === 4 ? '#1CB894' : e?.state === 2 ? '#BD5B44' : '#BD5B44',
                      textAlign: 'right',
                    }}>
                    {e?.state === 4
                      ? 'Filled'
                      : e?.state === 2
                        ? 'Canceled'
                        : e?.state === 6
                          ? 'Expired'
                          : ''}
                  </div>
                  <div className="text-[#999999] text-sm whitespace-pre">
                    {moment.unix(e.time_done).format('YYYY.MM.DD HH:mm:ss')}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <DialogComponent isOpen={dialog} onClose={() => setDialog(false)}>
        <div className="rounded-md bg-[#15181f] border border-[#2e2e2e]">
          <div className="px-5 pt-5 flex justify-between items-center">
            <div></div>
            <div className="text-md text-[#dededf] font-bold">Filter Order History</div>
            <svg
              onClick={() => setDialog(false)}
              className="cursor-pointer"
              width="10"
              height="10"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.781396 16.0001C0.626858 16.0001 0.475783 15.9543 0.347281 15.8685C0.218778 15.7826 0.118621 15.6606 0.0594776 15.5178C0.000334661 15.3751 -0.0151369 15.218 0.0150198 15.0664C0.0451766 14.9148 0.119607 14.7756 0.228896 14.6664L14.6664 0.228853C14.8129 0.0823209 15.0117 0 15.2189 0C15.4261 0 15.6249 0.0823209 15.7714 0.228853C15.9179 0.375385 16.0002 0.574125 16.0002 0.781353C16.0002 0.988581 15.9179 1.18732 15.7714 1.33385L1.3339 15.7714C1.26141 15.844 1.17528 15.9016 1.08047 15.9408C0.985653 15.9801 0.884016 16.0002 0.781396 16.0001Z"
                fill="#999999"
              />
              <path
                d="M15.2189 16.0001C15.1162 16.0002 15.0146 15.9801 14.9198 15.9408C14.825 15.9016 14.7388 15.844 14.6664 15.7714L0.228853 1.33385C0.0823209 1.18732 0 0.988581 0 0.781353C0 0.574125 0.0823209 0.375385 0.228853 0.228853C0.375385 0.0823209 0.574125 0 0.781353 0C0.988581 0 1.18732 0.0823209 1.33385 0.228853L15.7714 14.6664C15.8806 14.7756 15.9551 14.9148 15.9852 15.0664C16.0154 15.218 15.9999 15.3751 15.9408 15.5178C15.8816 15.6606 15.7815 15.7826 15.653 15.8685C15.5245 15.9543 15.3734 16.0001 15.2189 16.0001Z"
                fill="#999999"
              />
            </svg>
          </div>
          <div className="pb-3">
            <div className="flex flex-col px-1 relative w-full">
              <div className="readonly top-[42px] left-0 w-full flex flex-col rounded-md">
                {FILTER_LIST.map((e, i) => (
                  <div
                    onClick={() => {
                      setMarketOption(e.key)
                      handleFilterHistory(+e.key)
                    }}
                    key={i}
                    className="text-white list-filter text-sm px-8 h-[30px] flex items-center hover:bg-[#169376] hover:rounded-md transition-all cursor-pointer relative">
                    <span>
                      <svg
                        className={`absolute left-0 top-0 translate-y-1 translate-x-3 ${
                          marketOption === e.key ? 'block' : 'hidden'
                        }`}
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          {' '}
                          <path
                            d="M4 12.6111L8.92308 17.5L20 6.5"
                            stroke="#169376"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"></path>
                          {' '}
                        </g>
                      </svg>
                    </span>
                    <span className="ml-2">{e.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {custom && (
            <DateFilter from={fromTo.from} to={fromTo.to} submitDate={submitDate} handleCustom={handleCustom} />
          )}
        </div>
      </DialogComponent>
    </div>
  )
}

export default Orders
