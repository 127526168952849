import { metadata } from './metadata';
import { SymbolClient } from '../grpc/generated/symbol_grpc_web_pb';
import { serverApiConfig } from '../config';
import { useAppDispatch } from '../redux/app';
import { setBidAsk } from '../redux/reducer/bidask';
import { useState } from 'react';
import { setStat } from '../redux/reducer/stat';
import emitter from '../utilities/emitter';

const { LastTickRequest } = require('../grpc/generated/symbol_pb.js');

let google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');

const useLastTick = () => {
  const request = new google_protobuf_empty_pb.Empty();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  const getLastTick = async (symbol) => {
    let symbolClient = new SymbolClient(serverApiConfig(), null, null);
    const lastTickRequest = new LastTickRequest();
    lastTickRequest.setSymbol(symbol);

    symbolClient.getLastTick(lastTickRequest, metadata(), (err, response_) => {
      try {
        const bid = response_?.u[1];
        const ask = response_?.u[2];
        const symbol = response_?.u[9];
        emitter.instance.emit('TickData', {
          [symbol]: {
            bid,
            ask
          }
        });
        dispatch(
          setBidAsk({
            bid,
            ask,
            symbol
          })
        );
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    });
  };

  const getAllLastTick = async () => {
    let symbolClient = new SymbolClient(serverApiConfig(), null, null);
    symbolClient.lastTick(request, metadata(), (err, response_) => {
      try {
        const a = response_?.u[1]?.reduce((a, b) => {
          return {
            ...a,
            [b[0]]: {
              symbol: b[0] ?? '',
              bid_high: b[2] ?? 0,
              bid_low: b[3] ?? 0,
              ask_high: b[4] ?? 0,
              ask_low: b[5] ?? 0,
              datetime_msc: b[24] ?? 0,
              price_open: b[25] ?? 0,
              price_close: b[26] ?? 0
            }
          };
        }, {});
        const b = response_?.u[0]?.reduce((a, b) => {
          return {
            ...a,
            [b[9]]: {
              symbol: b[9] ?? '',
              bid: b[1],
              ask: b[2],
              datetime_msc: b[5]
            }
          };
        }, {});
        dispatch(
          setStat({
            tick: b,
            stat: a
          })
        );
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    });
  };

  return { getLastTick, loading, getAllLastTick };
};

export default useLastTick;
