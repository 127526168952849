import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
// pages
import { Login } from '../components/Login';
import LoadingProvider from '../components/LoadingProvider';
import { useAppDispatch } from '../redux/app';

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const dispatch = useAppDispatch();
  const { isAuthenticated, isInitialized, activeAccount, accounts } = useAuth();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);
  if (isInitialized) {
    if (window.location.origin === 'https://trading.iuxmarkets.com') {
      window.location.replace('https://trading.iux.com');
    }
    return <LoadingProvider />;
  }

  if (!isAuthenticated) {
    if (window.location.origin === 'https://trading.iuxmarkets.com') {
      window.location.replace('https://trading.iux.com');
    }
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to="login" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
