import { get, post } from '../utilities/http';
const main = process.env.REACT_APP_API_URL;
export const loginApi = async (data) => {
  return post(`${main}/v2/oauth/token`, data);
};

export const accountTradeApi = async () => {
  return get(`${main}/v2/user/account`);
};

export const serverApi = async () => {
  return get(`${main}/v2/trade/server`);
};

export const firebaseCustomApi = async () => {
  return get(`${main}/v2/firebase/token/custom`);
};

export const getMe = async () => {
  return get(`${main}/v2/me`);
};

export const createOtpApi = async () => {
  return post(`${main}/v2/user/otp`);
};

export const verifyOtpApi = async (body) => {
  return post(`${main}/v2/user/otp/verify`, body);
};
