import React from 'react';
import PropTypes from 'prop-types';
import {Navigate, useParams} from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import LoadingProvider from '../components/LoadingProvider';
import Navbar from '../components/Navbar';
import {Trans, useTranslation} from 'react-i18next';

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({children}) {
  let {token = ''} = useParams();
  const {isAuthenticated, isInitialized, accounts} = useAuth();
  const {t, i18n} = useTranslation();
  if (!isAuthenticated) {
    // return <Navigate to="/login" />;
    // window.location.href = '/login';
  }
  if (isAuthenticated) {
    if (window.location.origin === 'https://trading.iuxmarkets.com') {
      window.location.replace('https://trading.iux.com');
    }
    return <Navigate to="/trade-panel"/>;
  }

  if (isInitialized) {
    return <LoadingProvider/>;
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };

  return (
    <>

      <div id="loginForm" className="login-form">
        <div className="px-4 lg:px-8 pb-8 container">
          <Navbar handleChangeLang={changeLanguage}/>
          <div className="text-[#aaaaaa] text-md font-sans text-center mb-[40px]">
            <Trans
              t={t}
              i18nKey="head"
              components={{
                bi: <b/>
              }}
            />
          </div>
          {children}
        </div>
        <div className="bg-[#272727] px-4">
          <div className="container mx-auto">
            <div>
              <div className="h-10"/>
              <div className="flex justify-between">
                <div className="flex items-center">
                  <img
                    className="h-[82px]"
                    src="/images/iuxtrade/IUXTrade-main-logo.png"
                    alt="iux.com"
                  />
                </div>
                <div/>
              </div>
            </div>
            <p className="text-[#aaaaaa] text-xs mt-[38px] mb-4">{t('desc_1')}</p>
            <p className="text-[#aaaaaa] text-xs mb-4">{t('desc_2')}</p>
            <p className="text-[#aaaaaa] text-xs mb-4">{t('desc_3')}</p>
            <p className="text-[#aaaaaa] text-xs mb-4">{t('desc_4')}</p>
            <p className="text-[#aaaaaa] text-xs mb-4">
              <Trans
                t={t}
                i18nKey="desc_5"
                components={{
                  bi: <a href="https://iuxtrade.com" className="text-[#1CB894] hover:underline" target="_blank"
                         rel="noopener noreferrer"/>
                }}
              />
            </p>
            <div className="h-12"/>
          </div>
        </div>
      </div>
    </>
  );
}
