import moment from 'moment';
import { serverApiConfig } from '../config';
import EventEmitter from '../utilities/service';
const { AccountClient } = require('../grpc/generated/account_grpc_web_pb');

export default class UserHistory {
  async userHistory(request, metadata, mode) {
    let accountClient = new AccountClient(
      serverApiConfig(localStorage.getItem('trade.server')),
      null,
      null
    );
    request.setFrom(0);
    request.setTo(moment().valueOf());
    request.setMode(mode);
    request.setSymbol('');
    await accountClient.getUserHistory(request, metadata, (err, response_) => {
      if (response_ !== null) {
        EventEmitter.dispatch('history', {
          mode: mode,
          data: response_
        });
      }
    });
  }
}
