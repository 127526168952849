import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DialogComponent from '../../Dialog';
import DateFilter from '../DateFilter';
import { actionsTypes } from '../../../config';

const Positions = ({ dialog, setDialog }) => {
  const [positionInfo, setPositionInfo] = useState({
    credit: 0,
    deposit: 0,
    withdrawal: 0,
    profit: 0,
    balance: 0,
    commission: 0
  });

  const [open, setOpen] = useState(false);
  const [marketOption, setMarketOption] = useState(7);

  const { histories } = useSelector(({ histories }) => histories);
  const { instruments } = useSelector(({ symbol }) => symbol);
  const { isOpen, type } = useSelector(({ tradeDialog }) => tradeDialog);
  const [custom, setCustom] = useState(false);
  const [fromTo, setFromTo] = useState({
    from: '',
    to: ''
  });

  const [history, setHistory] = useState([]);

  const [historyFilter, setHistoryFilter] = useState({
    index: 7,
    data: []
  });

  const [imageOfOrder, setImageOfOrder] = useState({ ['']: '' });

  useEffect(() => {
    if (history.length <= 0) {
      let history = [];
      histories.forEach((item, index) => {
        let historyInfo = {
          ticket: item[0] ?? 0,
          action: item[1] ?? 0,
          timestamp: item[2],
          comment: item[3] ?? '',
          profit: item[4] ?? 0,
          close_price: item[5],
          open_time: item[6],
          close_time: item[7],
          tp: item[8] ?? null,
          sl: item[9] ?? null,
          open_price: item[10],
          volume: item[11] ?? 0,
          digits: item[12],
          symbol: item[13],
          contract_size: item[14],
          login: item[15],
          commission: item[17] ?? 0,
          time_format: moment.unix(item[2]).format('YYYY-MM-DD')
        };
        history.push(historyInfo);
      });

      const today = moment();
      const filtered = history.filter((e) => moment(e.time_format).isSame(today, 'day'));
      const credit = filtered
        .filter((e) => e.action === 3)
        .reduce((a, b) => (b.profit > 0 ? a + b.profit : 0), 0);
      const deposit = filtered
        .filter((e) => e.action === 2)
        .reduce((a, b) => (b.profit > 0 ? a + b.profit : 0), 0);
      const withdrawal = filtered
        .filter((e) => e.action === 2)
        .reduce((a, b) => (b.profit < 0 ? a + b.profit : 0), 0);
      const preProfit = filtered.filter((e) => {
        if (e.action === 0 || e.action === 1) {
          return e.profit;
        }
      });
      const profit = preProfit.reduce((a, b) => a + b.profit, 0);
      const balance = filtered.reduce((a, b) => a + b.profit + b.commission, 0);
      const commission = filtered.reduce((a, b) => a + b.commission, 0);
      setPositionInfo({
        credit,
        deposit,
        withdrawal,
        profit,
        balance,
        commission
      });
      setHistory(history);
      setHistoryFilter((prev) => ({
        index: 7,
        data: filtered.reverse()
      }));
    }
  }, [histories]);

  const handleFilterHistory = (value) => {
    const today = moment();
    const lastWeek = moment().subtract(7, 'day').unix();
    const lastMonth = moment().subtract(1, 'month').unix();
    const last3Month = moment().subtract(3, 'month').unix();
    const last6Month = moment().subtract(6, 'month').unix();
    const lastYear = moment().subtract(1, 'year').unix();

    let a = '';

    if (value === 0) a = lastWeek;
    if (value === 1) a = lastMonth;
    if (value === 2) a = last3Month;
    if (value === 3) a = last6Month;
    if (value === 4) a = lastYear;
    if (value === 6) a = 0;
    if (value === 7) a = today;
    let filtered = [];
    if (value === 7) filtered = history.filter((e) => moment(e.time_format).isSame(a, 'day'));
    else filtered = history.filter((e) => moment(e.timestamp).isAfter(a));
    try {
      if (+value !== 5) {
        const credit = filtered.filter((e) => e.action === 3).reduce((a, b) => a + b.profit, 0);
        const deposit = filtered
          .filter((e) => e.action === 2 && e.profit > 0)
          .reduce((a, b) => (b.profit > 0 ? a + b.profit : 0), 0);
        const withdrawal = filtered
          .filter((e) => e.action === 2 && e.profit < 0)
          .reduce((a, b) => (b.profit < 0 ? a + b.profit : 0), 0);
        const preProfit = filtered.filter((e) => {
          if (e.action === 0 || e.action === 1) {
            return e.profit;
          }
        });
        const profit = preProfit.reduce((a, b) => a + b.profit, 0);
        const balance = filtered.reduce((a, b) => a + b.profit + b.commission, 0);
        const commission = filtered.reduce((a, b) => a + b.commission, 0);
        setPositionInfo({
          credit,
          deposit,
          withdrawal,
          profit,
          balance,
          commission
        });
        setHistoryFilter((prev) => ({
          index: +value,
          data: filtered.reverse()
        }));
        setCustom(false);
      } else {
        setCustom(true);
        setHistoryFilter((prev) => ({
          ...prev,
          index: +value
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCustom = (value, from) => {
    if (from) {
      setFromTo((prev) => ({
        ...prev,
        from: moment(value).utc().format('YYYY-MM-DD')
      }));
    } else {
      setFromTo((prev) => ({
        ...prev,
        to: moment(value).add(1, 'day').utc().format('YYYY-MM-DD')
      }));
    }
  };

  const submitDate = () => {
    const filtered = history.filter((e) => moment(e.time_format).isBetween(fromTo.from, fromTo.to));
    const credit = filtered.filter((e) => e.action === 3).reduce((a, b) => a + b.profit, 0);
    const deposit = filtered
      .filter((e) => e.action === 2 && e.profit > 0)
      .reduce((a, b) => (b.profit > 0 ? a + b.profit : 0), 0);
    const withdrawal = filtered
      .filter((e) => e.action === 2 && e.profit < 0)
      .reduce((a, b) => (b.profit < 0 ? a + b.profit : 0), 0);
    const preProfit = filtered.filter((e) => {
      if (e.action === 0 || e.action === 1) {
        return e.profit;
      }
    });
    const profit = preProfit.reduce((a, b) => a + b.profit, 0);
    const balance = filtered.reduce((a, b) => a + b.profit + b.commission, 0);
    const commission = filtered.reduce((a, b) => a + b.commission, 0);
    setHistoryFilter((prev) => ({
      ...prev,
      data: filtered
    }));
    setPositionInfo({
      credit,
      deposit,
      withdrawal,
      profit,
      balance,
      commission
    });
    setDialog(false);
  };

  useEffect(() => {
    const a = instruments.reduce((a, b) => {
      return {
        ...a,
        [b.symbol]: b.logo ?? []
      };
    }, {});
    setImageOfOrder(a);
  }, [history]);

  const renderAction = (x) => {
    switch (x) {
      case 2:
        return <div className="font-bold text-[#1F6FDE]">Balance</div>;
      case 3:
        return <div className="font-bold text-[#1F6FDE]">Credit</div>;
      default:
        return (
          <div style={{ color: actionsTypes[x]?.color, marginLeft: '8px' }} className="font-bold">
            {actionsTypes[x]?.name}
          </div>
        );
    }
  };

  const List = [
    {
      name: 'All',
      key: 6
    },
    {
      name: 'Today',
      key: 7
    },
    {
      name: 'Last Week',
      key: 0
    },
    {
      name: 'Last Month',
      key: 1
    },
    {
      name: 'Last 3 Month',
      key: 2
    },
    {
      name: 'Last 6 Month',
      key: 3
    },
    {
      name: 'Last Year',
      key: 4
    },
    {
      name: 'Custom',
      key: 5
    }
  ];

  return (
    <div>
      <div className="history-dialog">
        <div className="grid grid-cols-2 p-3 gap-y-2 text-[#c1c1c1]">
          <div className="">Credit</div>
          <div className="text-end">{positionInfo.credit.toFixed(2) ?? 0}</div>
          <div className="">Deposit</div>
          <div className="text-end">{positionInfo.deposit.toFixed(2)}</div>
          <div className="">Withdrawal</div>
          <div className="text-end">{positionInfo.withdrawal.toFixed(2)}</div>
          <div className="">Profit</div>
          <div className="text-end">{positionInfo.profit.toFixed(2)}</div>
          <div className="">Swap</div>
          <div className="text-end">0.00</div>
          <div className="">Commission</div>
          <div className="text-end">{positionInfo.commission.toFixed(2)}</div>
          <div className="">Balance</div>
          <div className="text-end">{positionInfo.balance.toFixed(2)}</div>
        </div>
        <div id="custom-order-scroll" className="table-order-scroll history">
          {historyFilter.data.map((e, i) => (
            <div
              key={i}
              className="table-body hover:bg-[#141414] h-[84px]"
              style={{
                padding: '14px 8px',
                opacity: type === 'history' ? 1 : 0,
                transformOrigin: 'top'
              }}>
              <div className="flex h-full">
                <div className="flex w-full">
                  <div className="w-10">
                    {e.action !== 2 && e.action !== 3 ? (
                      <img
                        src={`https://storage.googleapis.com/iux-storage/symbol/${e.symbol}.png`}
                        alt="..."
                        onError={({ currentTarget }) => {
                          currentTarget.src = 'globe.svg';
                        }}
                        className="w-full rounded-[50%]"
                      />
                    ) : (
                      <div style={{ width: '28px' }}></div>
                    )}
                  </div>
                  <div className="ml-3 flex flex-col justify-between">
                    <div className="flex items-baseline">
                      <div className="text-white text-sm">{e.symbol}</div>
                      {renderAction(e.action)}
                    </div>
                    <div className="flex text-white">
                      <div style={{ color: '#999999' }} className="flex items-center">
                        <div className="text-sm">{e.open_price?.toFixed(e.digits)}</div>
                        {e.open_price && e.open_price ? (
                          <svg
                            className="mx-2"
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M9.88543 2.72349C9.88531 2.72338 9.88521 2.72324 9.88508 2.72312L7.84398 0.691874C7.69107 0.539706 7.44375 0.540272 7.29154 0.693202C7.13936 0.846112 7.13994 1.09344 7.29285 1.24562L8.66324 2.60937H0.390625C0.174883 2.60937 0 2.78426 0 3C0 3.21574 0.174883 3.39062 0.390625 3.39062H8.66322L7.29287 4.75437C7.13996 4.90656 7.13938 5.15389 7.29156 5.3068C7.44377 5.45974 7.69111 5.46027 7.844 5.30812L9.8851 3.27687C9.88521 3.27676 9.88531 3.27662 9.88545 3.2765C10.0384 3.12381 10.0379 2.87568 9.88543 2.72349Z"
                              fill="#999999"
                            />
                          </svg>
                        ) : (
                          <div className="text-sm">{e.comment}</div>
                        )}
                        <div className="text-sm">{e.close_price?.toFixed(e.digits)}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-between">
                  <div
                    className="text-end"
                    style={{
                      color: e?.profit < 0 ? '#BD5B44' : '#1F6FDE'
                    }}>
                    {e.profit?.toFixed(2)}
                  </div>
                  <div className="text-[#999999] text-sm whitespace-pre">
                    {moment.unix(e.close_time).utc().format('YYYY.MM.DD HH:mm:ss')}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <DialogComponent isOpen={dialog} onClose={() => setDialog(false)}>
        <div className="rounded-md bg-[#15181f] border border-[#2e2e2e]">
          <div className="px-5 pt-5 flex justify-between items-center">
            <div></div>
            <div className="text-md text-[#dededf] font-bold">Filter History</div>
            <svg
              onClick={() => setDialog(false)}
              className="cursor-pointer"
              width="10"
              height="10"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.781396 16.0001C0.626858 16.0001 0.475783 15.9543 0.347281 15.8685C0.218778 15.7826 0.118621 15.6606 0.0594776 15.5178C0.000334661 15.3751 -0.0151369 15.218 0.0150198 15.0664C0.0451766 14.9148 0.119607 14.7756 0.228896 14.6664L14.6664 0.228853C14.8129 0.0823209 15.0117 0 15.2189 0C15.4261 0 15.6249 0.0823209 15.7714 0.228853C15.9179 0.375385 16.0002 0.574125 16.0002 0.781353C16.0002 0.988581 15.9179 1.18732 15.7714 1.33385L1.3339 15.7714C1.26141 15.844 1.17528 15.9016 1.08047 15.9408C0.985653 15.9801 0.884016 16.0002 0.781396 16.0001Z"
                fill="#999999"
              />
              <path
                d="M15.2189 16.0001C15.1162 16.0002 15.0146 15.9801 14.9198 15.9408C14.825 15.9016 14.7388 15.844 14.6664 15.7714L0.228853 1.33385C0.0823209 1.18732 0 0.988581 0 0.781353C0 0.574125 0.0823209 0.375385 0.228853 0.228853C0.375385 0.0823209 0.574125 0 0.781353 0C0.988581 0 1.18732 0.0823209 1.33385 0.228853L15.7714 14.6664C15.8806 14.7756 15.9551 14.9148 15.9852 15.0664C16.0154 15.218 15.9999 15.3751 15.9408 15.5178C15.8816 15.6606 15.7815 15.7826 15.653 15.8685C15.5245 15.9543 15.3734 16.0001 15.2189 16.0001Z"
                fill="#999999"
              />
            </svg>
          </div>
          <div className="pb-3">
            <div className="flex flex-col px-1 relative w-full">
              {/* <div className="flex justify-between items-center cursor-pointer h-[47px]">
                <div className="text-white text-sm">{List[marketOption - 1]}</div>
              </div> */}
              <div className="readonly top-[42px] left-0 w-full flex flex-col rounded-md">
                {List.map((e, i) => (
                  <div
                    onClick={() => {
                      setMarketOption(e.key);
                      handleFilterHistory(+e.key);
                      setOpen(false);
                    }}
                    key={i}
                    className="text-white list-filter text-sm px-8 h-[30px] flex items-center hover:bg-[#169376] hover:rounded-md transition-all cursor-pointer relative">
                    <span>
                      <svg
                        className={`absolute left-0 top-0 translate-y-1 translate-x-3 ${
                          marketOption === e.key ? 'block' : 'hidden'
                        }`}
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          {' '}
                          <path
                            d="M4 12.6111L8.92308 17.5L20 6.5"
                            stroke="#169376"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"></path>{' '}
                        </g>
                      </svg>
                    </span>
                    <span className="ml-2">{e.name}</span>
                  </div>
                ))}
              </div>
            </div>
            {/* <select
              onChange={({ target: { value } }) => handleFilterHistory(value)}
              defaultValue={historyFilter.index}>
              <option key={6} value={6}>
                All
              </option>
              <option key={7} value={7}>
                Today
              </option>
              <option key={0} value={0}>
                Last Week
              </option>
              <option key={1} value={1}>
                Last Month
              </option>
              <option key={2} value={2}>
                Last 3 Month
              </option>
              <option key={3} value={3}>
                Last 6 Month
              </option>
              <option key={4} value={4}>
                Last Year
              </option>
              <option key={5} value={5}>
                Custom
              </option>
            </select> */}
          </div>
          {custom && (
            <DateFilter
              from={fromTo.from}
              to={fromTo.to}
              submitDate={submitDate}
              handleCustom={handleCustom}
            />
          )}
        </div>
      </DialogComponent>
    </div>
  );
};

export default Positions;
