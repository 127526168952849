import React, { useRef } from 'react';
import { setPayload } from '../../redux/reducer/payload';
import moment from 'moment/moment';
import { useAppDispatch, useAppSelector } from '../../redux/app';
import cn from '../../utilities/cn';

const ExpirationDate = ({ className, labelClass, inputClass, iconClass }) => {
  const dispatch = useAppDispatch();
  const dateRef = useRef(null);
  const payload = useAppSelector((state) => state.payload?.payload);

  return (
    <div className={cn('mt-4 px-[12px]', className)}>
      <div className={cn('flex justify-between mt-[10px] text-sm', labelClass)}>
        <div className="text-white">Time Expiration</div>
      </div>
      <div className="relative">
        <input
          ref={dateRef}
          onChange={({ target: { value } }) => {
            if (payload.type_time === 3) {
              // const timestamp = new Date().getTime();
              // const timeText = moment.utc(timestamp).format('HH:mm:ss'); 2024-11-02T00:00:00.000Z
              const inputTime = moment(`${value}T00:00:00.000Z`).utc().unix(); //2024-11-02T00:00:00.000Z
              dispatch(
                setPayload({
                  ...payload,
                  time_expiration: moment.tz('Europe/London').isDST() ? inputTime + 3600 : inputTime
                })
              );
            } else if (payload.type_time === 0) {
              dispatch(
                setPayload({
                  ...payload,
                  time_expiration: 0
                })
              );
            } else {
              dispatch(
                setPayload({
                  ...payload,
                  time_expiration: moment.tz('Europe/London').isDST()
                    ? moment(value).utc().unix() + 3600
                    : moment(value).utc().unix()
                })
              );
            }
          }}
          type={payload.type_time === 2 ? 'datetime-local' : 'date'}
          className={cn('input-date-time custom-date mt-[12px]', inputClass)}
          defaultValue={payload.time_expiration_format ?? null}
        />
        <div
          className={cn('absolute right-[15px] top-[23px] cursor-pointer', iconClass)}
          onClick={() => dateRef.current.showPicker()}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M3 10H21M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"></path>
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};
export default ExpirationDate;
