export const serverApiConfig = (server) => {
  let liveServer = window.electron?.store.get('trade.server') || server;
  let ser = '';

  if (liveServer) ser = liveServer;
  else ser = localStorage.getItem('trade.server') || window.electron?.store.get('trade.server');

  let metaServer = null;

  switch (ser) {
    case 'api':
      metaServer = 'https://trade-live.iuxmarkets.com';
      break;
    case 'live1':
      metaServer = 'https://trade-live1.iuxmarkets.com';
      break;
    case 'live2':
      metaServer = 'https://trade-live2.iuxmarkets.com';
      break;
    case 'live3':
      metaServer = 'https://trade-live3.iuxmarkets.com';
      break;
    case 'demo':
      metaServer = 'https://trade-demo.iuxmarkets.com';
      break;
    case 'live-test':
      metaServer = 'https://trade-live-test.iuxtrade.com';
      break;
    default:
      return;
  }
  return metaServer;
};

export const getAccountTradeId = () => {
  let id = 0;
  const accId =
    localStorage.getItem('iuxmarkets.active.account') ||
    window.electron?.store.get('iuxmarkets.active.account');
  if (accId) {
    id = accId;
  }
  return parseInt(id);
};

export const actionsTypes = {
  0: {
    name: 'Buy',
    color: '#1F6FDE'
  },
  1: {
    name: 'Sell',
    color: '#BD5B44'
  },

  2: {
    name: 'Buy limit',
    color: '#1F6FDE'
  },
  3: {
    name: 'Sell limit',
    color: '#BD5B44'
  },
  4: {
    name: 'Buy stop',
    color: '#1F6FDE'
  },
  5: {
    name: 'Sell stop',
    color: '#BD5B44'
  },
  6: {
    name: 'Buy stop limit',
    color: '#1F6FDE'
  },
  7: {
    name: 'Sell stop limit',
    color: '#BD5B44'
  },
  8: {
    name: 'Close By',
    color: '#FFC107'
  }
};

export const entrtyTypes = {
  undefined: {
    name: 'in',
    color: '#1F6FDE'
  },
  1: {
    name: 'out',
    color: '#1F6FDE'
  },
  2: {
    name: 'in/out',
    color: '#1F6FDE'
  },
  3: {
    name: 'out by',
    color: '#1F6FDE'
  }
};
