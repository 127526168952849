import { SymbolClient } from '../grpc/generated/symbol_grpc_web_pb';
import { serverApiConfig } from '../config';
import emitter from '../utilities/emitter';
import { SymbolName, TickRequest } from '../grpc/generated/symbol_pb';
import { initializeWorker } from '../worker/serviceWorker';
import { useAppDispatch } from '../redux/app';
import { setBidAsk } from '../redux/reducer/bidask';

let call;
let errorCount = 0;
let errorConnect = false;
let firstLoad = true;
const OnTick = () => {
  const dispatch = useAppDispatch();
  initializeWorker();
  const onSubscribe = async (
    request,
    metadata,
    resolution,
    onRealtimeCallback,
    activeSymbol,
    lastBarsCache,
    previousSymbol
  ) => {
    if (!call) {
      let symbolClient = new SymbolClient(
        serverApiConfig(localStorage.getItem('trade.server')),
        null,
        null
      );
      call = await symbolClient.onTick(request, metadata);
      call.on('data', function (response) {
        errorConnect = false;
        if (response.u[9] === activeSymbol) {
          emitter.instance.emit('bar', response);
        }
        emitter.instance.emit('TickData', {
          [response.u[9]]: {
            bid: response.u[1],
            ask: response.u[2],
            datetime_msc: response.u[5]
          }
        });
        dispatch(
          setBidAsk({
            bid: response.u[1],
            ask: response.u[2],
            symbol: response.u[9]
          })
        );
      });
      call.on('status', (status) => {});
      call.on('error', (err) => {
        errorCount++;
        errorConnect = true;
        try {
          localStorage.setItem('ActiveChart', activeSymbol);
          call?.cancel();
          // const metadata = { Authorization: 'Bearer ' + localStorage.getItem('trade.token') };
          const unsubRequest = new SymbolName();
          unsubRequest.setName(activeSymbol);
          symbolClient.tickUnsubscribe(unsubRequest, metadata, (err, response_) => {
            if (response_ == null) {
              return err;
            } else {
              setTimeout(() => {
                this.onSubscribe(
                  request,
                  metadata,
                  resolution,
                  onRealtimeCallback,
                  activeSymbol,
                  lastBarsCache,
                  previousSymbol
                );
                console.log('Reconect On Tick');
              }, 5000);
            }
          });
          if (errorCount > 3) {
            return;
          }
        } catch (error) {
          return;
        }
      });
    } else {
      let symbolClient = new SymbolClient(
        serverApiConfig(localStorage.getItem('trade.server')),
        null,
        null
      );
      if (errorConnect) {
        const symbol = new SymbolName();
        symbol.setName(activeSymbol);
        const request = new TickRequest();
        request.addSymbols(symbol);
        call = await symbolClient.onTick(request, metadata);
      } else {
        if (previousSymbol !== activeSymbol) {
          previousSymbol = activeSymbol;
          const newRequest = new SymbolName();
          newRequest.setName(activeSymbol);
          symbolClient.tickSubscribe(newRequest, metadata, (err, response_) => {
            if (response_ == null) {
              return err;
            } else {
              return;
            }
          });
        } else {
          if (firstLoad) {
            call = await symbolClient.onTick(request, metadata);
          } else {
            previousSymbol = activeSymbol;
            const newRequest = new SymbolName();
            newRequest.setName(activeSymbol);
            symbolClient.tickSubscribe(newRequest, metadata, (err, response_) => {
              if (response_ == null) {
                return err;
              }
            });
          }
        }
        call.on('data', function (response) {
          errorConnect = false;
          if (response.u[9] === activeSymbol) {
            emitter.instance.emit('bar', response);
          }
          dispatch(
            setBidAsk({
              bid: response.u[1],
              ask: response.u[2],
              symbol: response.u[9]
            })
          );
          emitter.instance.emit('TickData', {
            [response.u[9]]: {
              bid: response.u[1],
              ask: response.u[2],
              datetime_msc: response.u[5]
            }
          });
        });
        call.on('status', (status) => {});
        call.on('error', (err) => {
          errorCount++;
          errorConnect = true;
          try {
            localStorage.setItem('ActiveChart', activeSymbol);
            call?.cancel();
            const unsubRequest = new SymbolName();
            unsubRequest.setName(activeSymbol);
            symbolClient.tickUnsubscribe(unsubRequest, metadata, (err, response_) => {
              if (response_ == null) {
                return err;
              } else {
                setTimeout(() => {
                  setTimeout(() => {
                    this.onSubscribe(
                      request,
                      metadata,
                      resolution,
                      onRealtimeCallback,
                      activeSymbol,
                      lastBarsCache,
                      previousSymbol
                    );
                    console.log('Reconect On Tick');
                  }, 5000);
                }, 5000);
              }
            });
            if (errorCount > 3) {
              return;
            }
          } catch (error) {
            return;
          }
        });
      }
      firstLoad = false;
    }
  };

  return { onSubscribe };
};

export default OnTick;
